<template>
	<a-select
		allowClear
		:mode="mode"
		show-search
		:maxTagCount="4"
		:placeholder="_selfPlaceholder"
		:disabled="_selfDisabled"
		v-model:value="selectedValue"
		:filter-option="filterOption"
		@change="onChange"
	>
		<a-select-option v-for="(item, index) in option" :value="item.currency_code" :key="index">
			{{ item.currency_name }}
		</a-select-option>
	</a-select>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps, defineEmits } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"

const props = defineProps({
	placeholder: {},
	disabled: {},
	code: {},
	name: {},
	mode: {
		type: String,
		default: "",
	},
})

const emit = defineEmits(["update:code", "update:name", "change"])

const selectedValue = ref(undefined)
const option = ref([])

const _selfPlaceholder = computed(() => props.placeholder || "币种")
const _selfDisabled = computed(() => props.disabled || false)

const getList = async () => {
	let res = await axios.post(api.queryCurrencyPage, {})
	let list = res.list || []
	option.value = list
	console.log("props.code: ", props.code)
	if (props.code != undefined) {
		onChange(props.code)
	} else if (list.length === 1) {
		onChange(list[0].currency_code)
	}
}

const onChange = (value) => {
	selectedValue.value = value
	emit("update:code", value)
	let item = option.value.find((elem) => elem.currency_code === value)
	emit("update:name", item?.currency_name)
	emit("change", item)
}

const filterOption = (input, option) => {
	return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

watch(
	() => props.code,
	(val) => {
		selectedValue.value = val
	}
)

onMounted(() => {
	getList()
})
</script>
