<template>
	<div class="date-picker">
		<a-date-picker
			v-if="singleDatePicker"
			style="width: 100%"
			v-bind="propsValue"
			:placeholder="singleplaceholder"
			:value="value || defaultValue"
			@change="onChange"
		/>
		<a-range-picker
			v-else
			style="width: 100%"
			v-bind="propsValue"
			:placeholder="rangePlaceholder"
			:ranges="ranges"
			:value="rangeValue || defaultValue"
			@change="onChange"
		/>
	</div>
</template>

<script>
import { ref, computed, nextTick, onMounted } from "vue"
import dayjs from "dayjs"

export default {
	props: {
		startTime: {},
		endTime: {},
		placeholder: {},
		id: {},
		needhms: {
			type: Boolean,
			default: true,
		},
		singleDatePicker: {},
		defaultType: {
			type: Number,
		},
	},
	setup(props, context) {
		const value = ref(null)
		const rangeValue = ref(null)
		const rangePlaceholder = computed(() => {
			return props.placeholder ? [props.placeholder.slice(0, props.placeholder.length), props.placeholder.slice(0, props.placeholder.length)] : null
		})
		const singleplaceholder = computed(() => {
			return props.placeholder ? props.placeholder : "SelectTime"
		})
		const propsValue = computed(() => {
			return {
				id: props.id,
				showTime: props.needhms,
				ranges: ranges,
				format: props.needhms ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD",
			}
		})
		onMounted(() => {
			if (props.defaultType) {
				rangeValue.value = [dayjs().subtract(props.defaultType, "days"), dayjs().subtract(0, "days").endOf("days")]
				let data = {
					start_time: rangeValue.value[0].format("YYYY-MM-DD HH:mm:ss"),
					end_time: rangeValue.value[1].format("YYYY-MM-DD HH:mm:ss"),
				}
				context.emit("get_date", data)
			}
		})
		const defaultValue = computed(() => {
			let defaultValue = null
			if (props.singleDatePicker) {
				defaultValue = props.startTime ? dayjs(props.startTime) : null
			} else {
				if (props.startTime && props.endTime) {
					defaultValue = [dayjs(props.startTime), dayjs(props.endTime)]
				}
			}
			return defaultValue
		})
		const ranges = computed(() => {
			let ranges = {}
			let today = dayjs()
			today.$H = 23
			today.$m = 59
			today.$ms = 59
			ranges[`今天`] = [dayjs().startOf("days"), dayjs().subtract(0, "days").endOf("days")]
			ranges[`昨天`] = [dayjs().subtract(1, "days").startOf("days"), dayjs().subtract(1, "days").endOf("days")]
			ranges[`Last7days`] = [dayjs().subtract(7, "days"), dayjs().subtract(0, "days").endOf("days")]
			ranges[`Last30days`] = [dayjs().subtract(30, "days"), dayjs().subtract(0, "days").endOf("days")]
			ranges[`CurrentMonth`] = [dayjs().startOf("month"), dayjs().subtract(0, "days").endOf("days")]
			ranges[`LastMonth`] = [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")]
			return ranges
		})
		const format = computed(() => {
			let format = "YYYY-MM-DD HH:mm:ss"
			return format
		})
		const onChange = (m_value, val) => {
			// console.log("onChangeTime", m_value, value);
			value.value = m_value
			rangeValue.value = m_value

			let data = props.singleDatePicker ? val : { start_time: val[0], end_time: val[1] }
			// console.log(data, "data");
			context.emit("get_date", data)
			nextTick(() => {
				context.emit("changeList", "speed")
			})
		}

		const clear = () => {
			value.value = null
			rangeValue.value = null
		}
		return {
			value,
			rangeValue,
			rangePlaceholder,
			singleplaceholder,
			propsValue,
			defaultValue,
			ranges,
			format,
			onChange,
			clear,
		}
	},
}
</script>
<style scoped>
.date-input {
	width: 100%;
	background-color: #fff;
}
</style>
